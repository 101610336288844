import { useEffect, useRef } from "react";
import { ConditionalRenderProps, OtherProps } from "../types/Form";
import { FieldValues } from "react-hook-form";

export function ConditionalRender<T extends FieldValues, K extends keyof T = keyof T, O = OtherProps>(props: ConditionalRenderProps<T, K, O>) {
    const prevProps = useRef(props);
    const prevChildren = useRef(props.children);

    useEffect(() => {
        prevProps.current = props;
    }, [props]);

    if (!props.shouldRender || props.shouldRender(prevProps.current.monitor, props.monitor, props.otherValues)) {
        prevChildren.current = props.children;
        return props.children;
    }

    return prevChildren.current;
}
