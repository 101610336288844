import React from "react";
import { Divider, Heading } from "./ui";
import { AlertDialog, AlertDialogCancel, AlertDialogAction, AlertDialogPortal, AlertDialogContent, AlertDialogTitle } from "./ui/Alert-Dialog";

export interface ConfirmProps {
    open?: boolean;
    question?: string;
    description?: string;
    okTitle?: string;
    cancelTitle?: string;
    onClose?: (confirmed: boolean) => void;
}

export function Confirm(props: ConfirmProps) {
    function handleConfirm(confirmed: boolean) {
        if (props.onClose) {
            props.onClose(confirmed);
        }
    }

    return (
        <AlertDialog open={props.open ?? false}>
            <AlertDialogPortal>
                <AlertDialogContent onEscapeKeyDown={() => handleConfirm(false)}>
                    <AlertDialogTitle asChild>
                        <Heading level="h3" className="font-bold">
                            {props.question ?? "Are you sure?"}
                        </Heading>
                    </AlertDialogTitle>
                    {props.description && (
                        <>
                            <Divider />
                            <p>{props.description}</p>
                        </>
                    )}
                    <div className="flex justify-end gap-2">
                        <AlertDialogCancel onClick={() => handleConfirm(false)}>{props.cancelTitle ?? "Cancel"}</AlertDialogCancel>
                        <AlertDialogAction onClick={() => handleConfirm(true)}>{props.cancelTitle ?? "OK"}</AlertDialogAction>
                    </div>
                </AlertDialogContent>
            </AlertDialogPortal>
        </AlertDialog>
    );
}
