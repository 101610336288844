import React, { useEffect, useRef, useCallback, useMemo } from "react";
import debounce from "lodash/debounce";
import { useForm, FormProvider, FieldValues, UseFormReturn, UseFormProps, DefaultValues } from "react-hook-form";
import { ZodSchema, z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useDirtyValues } from "@/shared/hooks/useDirtyValues";

interface FormStepWrapperProps<T extends FieldValues> {
    schema: ZodSchema<T>;
    children: React.ReactNode;
    stepId: string;
    options?: UseFormProps<T>;
    register: (stepId: string, methods: UseFormReturn<T>) => void;
    unregister: (stepId: string) => void;
}

export function FormStepWrapper<T extends FieldValues>({ schema, children, options, register, unregister, stepId }: FormStepWrapperProps<T>) {
    const methods = useForm<z.infer<typeof schema>>({ resolver: zodResolver(schema), ...options });
    const { getValues, watch, reset } = methods;
    const { updateDirtyValues } = useDirtyValues<T>();
    const initialValuesRef = useRef<DefaultValues<T> | undefined>(typeof options?.defaultValues === "function" ? undefined : options?.defaultValues);

    useEffect(() => {
        register(stepId, methods);

        return () => {
            unregister(stepId);
        };
    }, [register, unregister, methods, stepId]);

    const updateDirtyValuesCallback = useCallback(() => {
        // updateDirtyValuesCallback: Compares current form values against initial values
        // to determine which fields have been modified (are "dirty"). It then updates
        // the dirty values context with these changes.
        //
        // This function:
        // 1. Retrieves current form values
        // 2. Compares each field with its initial value (if available)
        // 3. Considers a field dirty if:
        //    a) It has an initial value and the current value differs
        //    b) It had no initial value but now has a value
        // 4. Collects all dirty fields in a new object
        // 5. Updates the dirty values context if any changes are detected
        //
        // This callback is used in a watch effect to track form changes in real-time,
        // ensuring the dirty values context always reflects the current state of the form.

        const currentValues = getValues();
        const dirtyValues = Object.keys(currentValues).reduce((acc, key) => {
            const typedKey = key as keyof T;
            const initialValues = initialValuesRef.current;
            const initialValue = initialValues && typedKey in initialValues ? initialValues[typedKey as keyof typeof initialValues] : undefined;
            const currentValue = currentValues[typedKey];

            if (initialValue !== undefined && currentValue !== initialValue) {
                acc[typedKey] = currentValue;
            } else if (initialValue === undefined && currentValue !== undefined) {
                // If the field wasn't in initialValues but has a value now, it's dirty
                acc[typedKey] = currentValue;
            }
            return acc;
        }, {} as Partial<T>);

        if (Object.keys(dirtyValues).length > 0) {
            updateDirtyValues(dirtyValues);
        }
    }, [getValues, updateDirtyValues]);

    const debouncedUpdateDirtyValues = useMemo(() => debounce(updateDirtyValuesCallback, 300, { maxWait: 1000 }), [updateDirtyValuesCallback]);

    useEffect(() => {
        return () => {
            debouncedUpdateDirtyValues.cancel();
        };
    }, [debouncedUpdateDirtyValues]);

    useEffect(() => {
        // This will run on mount and whenever any form field changes
        const subscription = watch(() => {
            debouncedUpdateDirtyValues();
        });

        // Cleanup subscription on unmount
        return () => subscription.unsubscribe();
    }, [watch, debouncedUpdateDirtyValues]);

    useEffect(() => {
        const defaultValues = options?.defaultValues;
        if (defaultValues !== undefined && JSON.stringify(defaultValues) !== JSON.stringify(initialValuesRef.current)) {
            const resetForm = async () => {
                let values: DefaultValues<T> | undefined;

                if (typeof defaultValues === "function") {
                    try {
                        values = await (defaultValues as () => Promise<DefaultValues<T>>)();
                    } catch (error) {
                        console.error("Error getting default values:", error);
                        return;
                    }
                } else {
                    values = defaultValues;
                }

                initialValuesRef.current = values;

                reset(values, {
                    keepValues: true,
                    keepTouched: true,
                    keepDirtyValues: true,
                    keepDirty: true,
                    keepIsSubmitted: true,
                    keepSubmitCount: true,
                    keepErrors: true
                });
            };

            resetForm();
        }
    }, [options?.defaultValues, reset]);

    return <FormProvider {...methods}>{children}</FormProvider>;
}
