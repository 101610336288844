import { Quote } from "@/shared/types/Quote.interface";
import { QuoteDataUtils } from "@/shared/utils/QuoteDataUtils";
import { PartialUsPolicyStepType } from "@/quote-ptz-us/src/schema/PtzUsQuote";
import { PublicConfig } from "@/shared/PublicConfig";

export class PtzUsDataUtils {
    static getPolicyStepInitialValues(quote?: Quote): PartialUsPolicyStepType {
        // Destructure existing values from quote
        const { email, ratingZipcode, phone, policies, extra, firstName, lastName, id, marketing, lastStepID } = quote || {};
        // Use existing values if available, otherwise fall back to defaults
        const initialValues: PartialUsPolicyStepType = {
            firstName: firstName || "",
            lastName: lastName || "",
            email: email || "",
            phone: phone || "",
            ratingZipcode: ratingZipcode || "",
            policies: !!id && policies?.length ? policies : [QuoteDataUtils.createNewPolicyUS()],
            marketing: !!lastStepID
                ? {
                      hasEmailConsent: true,
                      hasSMSConsent: true
                  }
                : {
                      hasEmailConsent: marketing?.hasEmailConsent ?? true,
                      hasSMSConsent: marketing?.hasSMSConsent ?? true
                  },
            extra: extra || {}
        };

        return initialValues;
    }

    static async getStateFromZip(ratingZipcode: string, country: string = "US"): Promise<{ abbreviation: string; name: string } | null> {
        const endpoint = `${PublicConfig.SPOT_API_ROOT_URL}/utils/postal-code/${ratingZipcode}?country=${country}`;
        try {
            const response = await fetch(endpoint);
            if (!response.ok) {
                throw new Error(`Server responded with status ${response.status}`);
            }
            const contentType = response.headers.get("content-type");
            if (!contentType || !contentType.includes("application/json")) {
                throw new Error("Received non-JSON response from server");
            }
            const data = await response.json();
            return data.state;
        } catch (error) {
            // Ignore "fetching state from zip" error (user inputted invalid postal code).
            return null;
        }
    }

    static async isValidGiftcard(ratingZipcode: string): Promise<boolean> {
        const state = await this.getStateFromZip(ratingZipcode);
        if (state) {
            const usfStates = PublicConfig.PTZ_US.VALID_GIFTCARD_STATES_USF;
            const iaicStates = PublicConfig.PTZ_US.VALID_GIFTCARD_STATES_IAIC;
            return usfStates.includes(state.abbreviation) || iaicStates.includes(state.abbreviation);
        }
        return false;
    }
}
