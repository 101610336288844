// packages
import React, { useEffect, useState, useMemo } from "react";
import { useFormContext, Controller } from "react-hook-form";

// hooks
import { useAppLayerContext } from "@/shared/contexts/AppLayer";
import { useStateFromZipcode } from "@/shared/hooks/useRatingAddress";
import { useEmailSuggestion } from "../hooks/useEmailSuggestion";

// utils
import Strings from "@/shared/utils/Strings.constants";
import { UIUtils } from "@/shared/utils/UIUtils";
import { cn } from "../utils";

// components
import { FormField } from "@/shared/components/FormField";
import { InputWithLabel } from "@/shared/components/ui/InputWithLabel";
import GiftCardFieldWrapper from "@/shared/components/GiftCardFieldWrapper";
import { EmailSuggestionWrapper } from "./EmailSuggestionWrapper";
import MemberCenterModal from "@/shared/components/MemberCenterModal";

// types
import { ErrorIdType } from "@/shared/types/SpotAPI";

interface UserContactConfigProps {
    config: {
        showFirstName?: boolean;
        showLastName?: boolean;
        showMobilePhone?: boolean;
        underwriter: "ptz-us" | "ptz-ca";
        errors: {
            "invalid-postal-code": string;
        };
        zipField: {
            label: string;
            placeholder?: string;
            mask: (value: string) => string;
            showGiftCardWrapper: boolean;
        };
        isNoStepVariant?: boolean;
    };
    styles?: {
        wrapper?: string;
        fields?: {
            firstName?: string;
            lastName?: string;
            phone?: string;
            email?: string;
            zipCode?: string;
        };
    };
}

export function UserContactEditor(props: UserContactConfigProps) {
    const { showFirstName = false, showLastName = false, showMobilePhone = false, zipField, underwriter, errors: errorMessages, isNoStepVariant } = props.config;
    const { styles } = props;

    const {
        control,
        setValue,
        setError,
        clearErrors,
        watch,
        setFocus,
        formState: { errors, submitCount }
    } = useFormContext();

    const email = watch("email");
    const [isEmailSuggestionShowing, setIsEmailSuggestionShowing] = useState(false);
    const { showSuggestion, suggestedEmail, handleSuggestionConfirm, handleSuggestionDismiss } = useEmailSuggestion(email);
    const { appState, updateAppState } = useAppLayerContext();
    const [showModal, setShowModal] = useState(false);
    const { asyncErrors } = appState;
    const currentRatingZipcode = watch(`ratingZipcode`);
    const { stateFromZip } = useStateFromZipcode(currentRatingZipcode);

    const allUserContactErrorsMap: Partial<Record<ErrorIdType, { path: string; message: string; showModal?: boolean }>> = useMemo(
        () => ({
            "invalid-postal-code": { path: "ratingZipcode", message: errorMessages["invalid-postal-code"] },
            "location-not-available": { path: "ratingZipcode", message: Strings.ERRORS.LOCATION_UNAVAILABLE },
            "invalid-phone-number": { path: "phone", message: "Invalid phone number" },
            "account-exists": { path: "email", message: "This email is already registered", showModal: true }
        }),
        [errorMessages]
    );

    useEffect(() => {
        if ((asyncErrors && asyncErrors.length > 0 && submitCount > 0) || isEmailSuggestionShowing) {
            clearErrors();

            let handledAllErrors = true;

            if (isEmailSuggestionShowing) {
                setFocus("email");
            } else if (asyncErrors) {
                asyncErrors.forEach(error => {
                    const errorMapping = allUserContactErrorsMap[error.id];
                    if (errorMapping) {
                        if (errorMapping.showModal) {
                            setShowModal(true);
                        }
                        setError(errorMapping.path, { message: errorMapping.message });
                        setFocus(errorMapping.path);
                    } else {
                        handledAllErrors = false;
                    }
                });
                if (!handledAllErrors) {
                    updateAppState({ hasUnknownError: true });
                }
            }
        }
    }, [asyncErrors, setError, setFocus, submitCount, clearErrors, allUserContactErrorsMap, updateAppState, isEmailSuggestionShowing]);

    useEffect(() => {
        setIsEmailSuggestionShowing(showSuggestion);
    }, [showSuggestion]);

    const renderModal = () => {
        if (asyncErrors?.some(error => error.id === `account-exists`)) {
            return <MemberCenterModal open={showModal} onClose={() => setShowModal(false)} />;
        }
    };

    return (
        <>
            {showModal && renderModal()}
            <div className={cn("flex flex-col gap-6", styles?.wrapper)}>
                <Controller
                    name="ratingZipcode"
                    control={control}
                    render={({ field: { ref, value, onChange, ...rest }, fieldState }) => {
                        return (
                            <GiftCardFieldWrapper stateFromZip={stateFromZip} showGiftCardWrapper={zipField.showGiftCardWrapper} cssWrapper={styles?.fields?.zipCode}>
                                <FormField
                                    className={cn("flex-1", !zipField.showGiftCardWrapper && styles?.fields?.zipCode)}
                                    error={fieldState?.error?.message}
                                    errorId="error-ratingZipcode"
                                >
                                    <InputWithLabel
                                        label={zipField?.label || Strings.ZIP_CODE}
                                        variant="floating"
                                        inputRef={ref}
                                        error={fieldState?.error?.message}
                                        inputProps={{
                                            ...rest,
                                            autoComplete: "postal-code",
                                            "aria-describedby": errors?.ratingZipcode ? "error-ratingZipcode" : undefined,
                                            placeholder: zipField?.placeholder,
                                            value: zipField.mask(value),
                                            onChange: e => onChange(zipField.mask(e.target.value)),
                                            onBlur: event => {
                                                rest.onBlur();
                                                const hasAsyncError = asyncErrors?.some(error => error.id === `invalid-postal-code` || error.id === `location-not-available`);
                                                if (hasAsyncError) {
                                                    updateAppState({
                                                        asyncErrors: asyncErrors?.filter(error => error.id !== `invalid-postal-code` && error.id !== `location-not-available`)
                                                    });
                                                }
                                            }
                                        }}
                                    />
                                </FormField>
                            </GiftCardFieldWrapper>
                        );
                    }}
                />

                <Controller
                    name="email"
                    control={control}
                    render={({ field: { ref, onChange, ...rest }, fieldState }) => {
                        return (
                            <EmailSuggestionWrapper
                                className={styles?.fields?.email}
                                showSuggestion={showSuggestion}
                                suggestedEmail={suggestedEmail}
                                onSuggestionConfirm={() => {
                                    handleSuggestionConfirm(onChange);
                                    setIsEmailSuggestionShowing(false);
                                    setFocus("email");
                                }}
                                onSuggestionDismiss={() => {
                                    handleSuggestionDismiss();
                                    setIsEmailSuggestionShowing(false);
                                    setFocus("email");
                                }}
                            >
                                <FormField className="flex-1" error={fieldState?.error?.message} errorId="error-email">
                                    <InputWithLabel
                                        label={Strings.EMAIL_ADDRESS}
                                        variant="floating"
                                        inputRef={ref}
                                        error={fieldState?.error?.message}
                                        inputProps={{
                                            ...rest,
                                            onChange: e => {
                                                const hasAsyncError = asyncErrors?.some(error => error.id === `account-exists`);
                                                if (hasAsyncError) {
                                                    updateAppState({ asyncErrors: asyncErrors?.filter(error => error.id !== `account-exists`) });
                                                }
                                                onChange(e);
                                            },
                                            placeholder: "my@email.com",
                                            type: "email",
                                            autoComplete: "email",
                                            "aria-describedby": errors?.email ? "error-email" : undefined
                                        }}
                                    />
                                </FormField>
                            </EmailSuggestionWrapper>
                        );
                    }}
                />

                {showFirstName && (
                    <Controller
                        name="firstName"
                        control={control}
                        render={({ field: { ref, ...rest }, fieldState }) => (
                            <FormField className={cn("flex-1", styles?.fields?.firstName)} error={fieldState?.error?.message} errorId="error-firstName">
                                <InputWithLabel
                                    label={Strings.FIRST_NAME}
                                    variant="floating"
                                    inputRef={ref}
                                    error={fieldState?.error?.message}
                                    inputProps={{
                                        ...rest,
                                        autoComplete: "given-name",
                                        "aria-describedby": errors?.firstName ? "error-firstName" : undefined,
                                        maxLength: 255
                                    }}
                                />
                            </FormField>
                        )}
                    />
                )}
                {showLastName && (
                    <Controller
                        name="lastName"
                        control={control}
                        render={({ field: { ref, ...rest }, fieldState }) => (
                            <FormField className={cn("flex-1", styles?.fields?.lastName)} error={fieldState?.error?.message} errorId="error-lastName">
                                <InputWithLabel
                                    label={Strings.LAST_NAME}
                                    inputRef={ref}
                                    variant="floating"
                                    error={fieldState?.error?.message}
                                    inputProps={{
                                        ...rest,
                                        autoComplete: "family-name",
                                        "aria-describedby": errors?.lastName ? "error-lastName" : undefined,
                                        maxLength: 255
                                    }}
                                />
                            </FormField>
                        )}
                    />
                )}
                {showMobilePhone && (
                    <Controller
                        name="phone"
                        control={control}
                        render={({ field: { ref, value, onChange, ...rest }, fieldState }) => (
                            <FormField className={cn("flex-1", styles?.fields?.phone)} error={fieldState?.error?.message} errorId="error-phone">
                                <InputWithLabel
                                    label={Strings.MOBILE_PHONE_OPTIONAL}
                                    inputRef={ref}
                                    variant="floating"
                                    error={fieldState?.error?.message}
                                    inputProps={{
                                        ...rest,
                                        autoComplete: "tel-national",
                                        placeholder: "(555) 555-5555",
                                        type: "tel",
                                        "aria-describedby": errors?.phone ? "error-phone" : undefined,
                                        value: UIUtils.maskPhone(value),
                                        onChange: e => onChange(UIUtils.maskPhone(e.target.value)),
                                        onBlur: event => {
                                            rest.onBlur();
                                            const hasAsyncError = asyncErrors?.some(error => error.id === `invalid-phone-number`);
                                            if (hasAsyncError) {
                                                updateAppState({ asyncErrors: asyncErrors?.filter(error => error.id !== `invalid-phone-number`) });
                                            }
                                        }
                                    }}
                                />
                            </FormField>
                        )}
                    />
                )}
            </div>
        </>
    );
}
